import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Table } from 'react-bootstrap';
import { EversoulCharacter } from '../../../modules/es/common/components/eversoul-character';

const EsGuidesIADR: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="es">
      <ul className="breadcrumb">
        <li>
          <Link to="/eversoul/">Eversoul</Link>
        </li>
        <li className="divider">/</li>
        <li>Subjugation - Adrianne</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/eversoul/categories/category_adr.png"
            alt="Subjugation - Adrianne"
          />
        </div>
        <div className="page-details">
          <h1>Subjugation - Adrianne</h1>
          <h2>
            Struggling with the Subjugation - Adrianne? This guide will help
            you!
          </h2>
          <p>
            Last updated: <strong>07/02/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Foreword" />
        <p>
          This guide was written for difficulty level 401, so not all mechanics
          may apply if you are doing a lower difficulty. Higher difficulty skill
          alterations are also not included. If you are unsure, read the skill
          descriptions provided in this guide!
        </p>
        <SectionHeader title="Adrianne Mechanics" />
        <p>
          The first most notable difference between Adrianne and other ESS is
          that Adri does not possess a groggy bar. This means that stacking CC
          isn’t necessary for this fight, however some CC is still required to
          avoid one of her high damage skills.{' '}
        </p>
        <h5>Charging Pierce</h5>
        <StaticImage
          src="../../../images/eversoul/adr/image1.webp"
          alt="Adrianne"
        />
        <p>
          This skill, if unleashed, will have Adrianne charge at the furthest
          soul from her. It packs quite a punch so it’s typically best to stun
          her out of it if it is killing your units. At 320+ she gains 6 stacks
          of Vulnerability Protection which need to be knocked off with any
          attack that hits her (including basic attacks) before she can be
          stunned. Make sure her stacks are gone before using your stun skill or
          the stun will fail.
        </p>
        <StaticImage
          src="../../../images/eversoul/adr/image2.webp"
          alt="Adrianne"
        />
        <p>
          <strong>
            When you see her charging up, clear her Vulnerability Protection
            skills, then use a stun!
          </strong>
        </p>
        <h5>Spears of the Apostle</h5>
        <StaticImage
          src="../../../images/eversoul/adr/image3.webp"
          alt="Adrianne"
        />
        <p>
          Periodically throughout the fight, Adrianne will summon 2 spears on
          the field. Whenever this happens, she becomes untargetable until the
          spears have been destroyed. At level 280+ these spears will each
          require 5 hits to break, and clearing them quickly is important as
          they apply stacks of an undispellable damage buff that persist
          throughout the fight to Adrianne the longer they are up. However, be
          careful not to waste important hard-hitting or stunning skills on the
          spears unless necessary, as the damage applied to them does not count
          toward damage on Adrianne.
        </p>
        <h5>End of Days</h5>
        <StaticImage
          src="../../../images/eversoul/adr/image4.webp"
          alt="Adrianne"
        />
        <p>
          When there is approximately 35 seconds left in the battle, Adrianne
          will move to the center of the stage and begin charging her biggest
          attack yet. Instead of summoning 2 spears, she now summons 4 that must
          be destroyed as quickly as possible while she is charging her skill
          (this takes approximately 20 seconds). The longer the spears are on
          the field, the more buffs she will acquire from them, so clearing
          these spears as fast as possible is very important to avoid an early
          demise. To that end, using large AoE skills or an ultimate is ideal
          for a quick clear. Talia’s ultimate is most strongly recommended for
          this!
        </p>
        <p>
          After she uses her tactical nuke, she goes into a stunned phase for 10
          seconds, during which she takes 100% more damage. All your souls’
          skills and ultimate will also be fully charged, making this the best
          time in the fight to dump your highest damage ultimate.{' '}
        </p>
        <SectionHeader title="Skills" />
        <Table striped bordered responsive className="banner-history">
          <tbody>
            <tr>
              <td rowSpan={4}>
                <p>
                  <span>Main</span>
                </p>
              </td>
              <td rowSpan={4}>
                <p>
                  <span>Obliterating Bombardment: Heaven&rsquo;s Strike</span>
                </p>
              </td>
              <td>
                <p>
                  <span>Basic</span>
                </p>
              </td>
              <td>
                <p>
                  <span>
                    The Apostle of War flies to the sky, creates an empowering
                    ring, and charges into the ring to obliterate everything.
                    Deals damage equal to 450% of ATK for every enemy.
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <span>Lv.201</span>
                </p>
              </td>
              <td>
                <p>
                  <span>The damage is increased to 600%.</span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <span>Lv.301</span>
                </p>
              </td>
              <td>
                <p>
                  <span>
                    The damage is increased to 750%&lt; and heals 3% of her HP.
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <span>Lv.400</span>
                </p>
              </td>
              <td>
                <p>
                  <span>
                    The damage is increased to 900%, and depletes Ultimate Gauge
                    of every enemy.
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td rowSpan={5}>
                <p>
                  <span>Sub</span>
                </p>
              </td>
              <td rowSpan={5}>
                <p>
                  <span>Light-Penetrating Strike: Charging Pierce</span>
                </p>
              </td>
              <td>
                <p>
                  <span>Basic</span>
                </p>
              </td>
              <td>
                <p>
                  <span>
                    The Apostle of War targets the farthest enemy and charges
                    energy on her spear. If the energy is fully charged, she
                    charges to the targeted enemy and deals damage equal to 500%
                    ATK, and pushes the target to the end of the battlefield.
                    During the energy charging, she is vulnerable to the stun
                    effect.
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <span>Lv.120</span>
                </p>
              </td>
              <td>
                <p>
                  <span>The damage is increased to 700%.</span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <span>Lv.220</span>
                </p>
              </td>
              <td>
                <p>
                  <span>
                    During the energy charging, she grants 3 stacks of
                    [Vulnerability Protection], which protects the stun effects.
                    The effect consumes the stack per attacks she received.
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <span>Lv.320</span>
                </p>
              </td>
              <td>
                <p>
                  <span>
                    The stacks of [Vulnerability Protection] is increased by 6
                    stacks.
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <span>Lv.420</span>
                </p>
              </td>
              <td>
                <p>
                  <span>
                    The damage is increased to 1000%, and if she has more than
                    10 stacks of [Deep Darkness], she becomes immune to the stun
                    effects.
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td rowSpan={5}>
                <p>
                  <span>Sub</span>
                </p>
              </td>
              <td rowSpan={5}>
                <p>
                  <span>Raid Feared by Darkness: Fearful Raid</span>
                </p>
              </td>
              <td>
                <p>
                  <span>Basic</span>
                </p>
              </td>
              <td>
                <p>
                  <span>
                    The Apostle of War jumps to the sky and descends to the
                    enemy who has the most HP, and deals damage to the target
                    and 4m around it. The damage is up to equal to 800% of ATK
                    according to the enemies affected.
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <span>Lv.140</span>
                </p>
              </td>
              <td>
                <p>
                  <span>The damage is increased to 1000%.</span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <span>Lv.240</span>
                </p>
              </td>
              <td>
                <p>
                  <span>
                    The damage is increased to 1200%, and damaged targets are
                    pushed away.
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <span>Lv.340</span>
                </p>
              </td>
              <td>
                <p>
                  <span>
                    If there is only one target damaged by the skill, the damage
                    is doubled.
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <span>Lv.440</span>
                </p>
              </td>
              <td>
                <p>
                  <span>The damage is increased to 1600%.</span>
                </p>
              </td>
            </tr>
            <tr>
              <td rowSpan={5}>
                <p>
                  <span>Sub</span>
                </p>
              </td>
              <td rowSpan={5}>
                <p>
                  <span>
                    An impact that penetrates the soul: Soul Penetration
                  </span>
                </p>
              </td>
              <td>
                <p>
                  <span>Basic</span>
                </p>
              </td>
              <td>
                <p>
                  <span>
                    The Apostle of War pierces the charged spear to the nearest
                    enemy, deals damage equal to 300% of ATK in the enemies
                    within 3m*10m area.
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <span>Lv.160</span>
                </p>
              </td>
              <td>
                <p>
                  <span>The damage is increased to 400%.</span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <span>Lv.260</span>
                </p>
              </td>
              <td>
                <p>
                  <span>
                    Targets affected by the skill grant [Penetrating Wound]
                    debuff, and take 70% ATK damage per 2 seconds. The debuff is
                    dispelled when the HP is full.
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <span>Lv.360</span>
                </p>
              </td>
              <td>
                <p>
                  <span>
                    The [Penetration Wound] also decreases the healing effects
                    by 50%.
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <span>Lv. 460</span>
                </p>
              </td>
              <td>
                <p>
                  <span>
                    Damage changes to 600%, and decreases mana recovery by 50%,
                    and &ldquo;Soul Penetration&rdquo; effect is granted, which
                    makes the healing effect from leech effect is disabled. The
                    effect is dispelled when &ldquo;Penetrating Damage&rdquo; is
                    removed.
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td rowSpan={6}>
                <p>
                  <span>Sub</span>
                </p>
              </td>
              <td rowSpan={6}>
                <p>
                  <span>A Termination to the Fools: End of Days</span>
                </p>
              </td>
              <td>
                <p>
                  <span>Basic</span>
                </p>
              </td>
              <td>
                <p>
                  <span>
                    After a specific time has passed since the battle started,
                    the Apostle of War moves to the center, pushes every enemy,
                    and summons 3 Spears of Apostle.
                  </span>
                </p>
                <p>
                  <span>
                    The Spear of Apostle is destroyed when taking attack 3
                    times, and the Apostle of War becomes invulnerable when the
                    Spear of Apostle exists.
                  </span>
                </p>
                <p>
                  <span>
                    Then gathers the energy of darkness to prepare for
                    obliteration, and when the energy is fully charged creates a
                    large explosion and deals damage equal to 800% of ATK to
                    every enemy.
                  </span>
                </p>
                <p>
                  <span>
                    If the enemy is not incapacitated by the attack, she loses
                    all [Deep Darkness] buff, and loses all of her Mana, and
                    takes 100% more damage for 10 seconds.
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <span>Lv.201</span>
                </p>
              </td>
              <td>
                <p>
                  <span>
                    The Apostle of War summons 2 Spears of Apostle on a specific
                    time cycle.
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <span>Lv.280</span>
                </p>
              </td>
              <td>
                <p>
                  <span>
                    The Spear of Apostle&rsquo;s durability is increased from 3
                    to 5, and the damage is increased to 1200%.
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <span>Lv.380</span>
                </p>
              </td>
              <td>
                <p>
                  <span>
                    The Spears of Apostle summoned from [End of Days] is
                    increased from 3 to 4
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <span>Lv. 480</span>
                </p>
              </td>
              <td>
                <p>
                  <span>
                    The durability of Spear of Apostle changes to 10, and heals
                    0.75% of max HP of self when &ldquo;Deep Darkness&rdquo;
                    effect is stacked.
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <span>Lv. 500</span>
                </p>
              </td>
              <td>
                <p>
                  <span>
                    Damage changes to 1100%, and summons 2 Spears of Apostle.
                  </span>
                </p>
              </td>
            </tr>
          </tbody>
        </Table>
        <SectionHeader title="Notable Characters" />
        <p>
          Since progressing through ESS has the option to build many teams, a
          lot of which will depend on your own roster and character investments
          on ascensions and artifacts, this guide will only include some team
          and character suggestions, not static team builds or play order.{' '}
        </p>
        <h5>Strongest DPS</h5>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="lizelotte" enablePopover />
        </div>
        <p>
          Bar none, Lizelotte is the best option for this fight. Not only is she
          the queen of single-target DPS, but she also gets to benefit from the
          Demon faction bonus in this fight, as Adrianne is an Angel. Saving her
          ultimate for the DPS phase near the end of the fight is strongly
          recommended.
        </p>
        <p>
          Replacing Lize in this fight will be difficult, but other strong
          single target DPS options would be your best bet if you don’t have
          her. Some suggestions include Aki, Edith, or Lute. Ayame and Eve are
          demon faction, so they are also good teammates to consider bringing
          along.
        </p>
        <h5>Buffers and Healers</h5>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="yuria" enablePopover />
          <EversoulCharacter mode="icon" slug="talia" enablePopover />
          <EversoulCharacter mode="icon" slug="eve" enablePopover />
          <EversoulCharacter mode="icon" slug="vivienne" enablePopover />
        </div>
        <p>
          As always, <strong>Yuria</strong> reigns supreme in the support
          category, as her kit provides way too much offensive and defensive
          power for your team. Since Lize also benefits from faction bonus here,
          her usefulness cannot be outdone.{' '}
        </p>
        <p>
          <strong>Talia</strong> is also a strong option, as her defensive buffs
          and mana battery help your team significantly. She also doubles as an
          AoE provider through use of her ultimate to help clear the spears!
        </p>
        <p>
          <strong>Eve</strong> is an amazing partner for your Lize, providing
          her with insane damage boosts, and feeding her mana so she can use her
          main skill more frequently. She also provides teamwide defensive
          boosts, and is a Demon herself, allowing her to supplement your
          damage.
        </p>
        <p>
          <strong>Vivienne</strong> is also a contender, providing a much
          appreciated speed boost to your team, and bringing decent DPS on top
          of that.
        </p>
        <h5>Stuns</h5>
        <div className="employee-container for-nikke">
          <EversoulCharacter mode="icon" slug="daphne" enablePopover />
          <EversoulCharacter mode="icon" slug="linzy" enablePopover />
          <EversoulCharacter mode="icon" slug="haru" enablePopover />
        </div>
        <p>
          <strong> Daphne</strong> is easily the best option here, as she not
          only brings an on-demand stun, but provides your team with both
          offensive and defensive buffs, helping you stay alive if you are
          struggling to survive the fight.
        </p>
        <p>
          <strong> Linzy</strong> is a frequently used option when stuns are
          required, as she provides strong DPS on top of her own ability to
          survive through her evasion-based kit.
        </p>
        <p>
          <strong> Haru</strong> is always a great option, as long as your team
          has no issues sticking close enough to her to activate her passive,
          allowing her to provide huge teamwide DPS boosts while also being able
          to stun.
        </p>
        <h5>Other considerations</h5>
        <p>
          Without naming any specific souls, some other teammates you may
          consider are units that provide mana drain and units with large AoE
          skills. The mana drain will provide your team with more survivability,
          as it will decrease the number of times you’ll be hit by Adrianne’s
          main skill. AoE is of course to aid in clearing the spears before they
          buff Adrianne to the point of dealing too much damage for your party
          to sustain.
        </p>
        <SectionHeader title="Example teams" />
        <StaticImage
          src="../../../images/eversoul/adr/image5.webp"
          alt="Adrianne"
        />
        <h5>THE team</h5>
        <p>
          The highest DPS and most successful team for this fight, if you can
          pull it off without needing stuns for her charge attack. This team is
          often able to one-shot the fight on its own, or if not, will only
          require some small damage from a second team to help it off. If more
          survivability is required, replacing Vivienne with Daphne will provide
          defensive buffs, damage sharing and a stun for the charge attack.
        </p>
        <p>
          Disclaimer: Your results may vary, as this team’s success is
          specifically tied to the investment on your Lizelotte.
        </p>
        <h5>Cleanup Options</h5>
        <p>
          Honestly, there is no specific cleanup team, it really comes down to
          what you have in your roster that provides all the ingredients
          necessary for the fight. High single-target DPS, heals, a stun and
          some AoE. Mana control for bonus survivability.
        </p>
        <StaticImage
          src="../../../images/eversoul/adr/image6.webp"
          alt="Adrianne"
        />
        <br />
        <StaticImage
          src="../../../images/eversoul/adr/image7.webp"
          alt="Adrianne"
        />
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default EsGuidesIADR;

export const Head: React.FC = () => (
  <Seo
    title="Subjugation - Adrianne | Eversoul | Prydwen Institute"
    description="Struggling with the Subjugation - Adrianne? This guide will help you!"
    game="eversoul"
  />
);
